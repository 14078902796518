<template>
  <div class="w-full">
    <div class="mx-auto w-full max-w-md rounded-2xl">
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          class="flex w-full justify-between rounded-lg py-2 text-left text-sm font-medium text-black focus:outline-none font-inter"
        >
          <div class="flex items-center">
            <div>
              <div
              class="h-2 w-2 rounded-full mr-1"
              :class="
                InspectionCheckItem.InspectionStatus != 'Pass'
                  ? 'bg-red-700'
                  : 'bg-green-700'
              "
            ></div>
            </div>
            
            <p class="ml-2">{{ InspectionCheckItem.CheckID.Check }}</p>
          </div>
          <font-awesome-icon
            :icon="['fas', 'angle-down']"
            :class="open ? 'rotate-180 transform' : ''"
          />
        </DisclosureButton>
        <DisclosurePanel class="p-2 text-gray-500">
          <div>
            <p class="font-semibold mb-1 text-xs">Comments</p>
            <p v-if="InspectionCheckItem.Comments">
              {{ InspectionCheckItem.Comments }}
            </p>
            <p v-else class="text-red-600 ml-2 text-sm">N/A</p>
          </div>
          <div>
            <p class="font-semibold mb-1 mt-2 text-xs">Images</p>
            <div v-if="InspectionCheckItem.ImageUrl">
              <a :href="InspectionCheckItem.ImageUrl" target="_blank" class="flex justify-end outline-none text-sm font-inter my-2">Download Image</a>
              <img :src="InspectionCheckItem.ImageUrl" class="w-full h-96 object-cover">
            </div>
            <p v-else class="text-red-600 ml-2 text-sm">N/A</p>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
</script>
<script>
export default {
  props: {
    InspectionCheckItem: {
      type: Object,
      required: true,
    },
  },
};
</script>
