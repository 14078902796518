export default class IDataService {
    constructor() {
        if (this.constructor === IDataService) {
            throw new TypeError("Abstract class IDataService cannot be Instantiated directly.")
        }
    }

    async login(...args) {
        throw new Error('Method "login({email, password})" should be implemented')
    }

    async getUserSession() {
        throw new Error('Method "getUserSession" should be implemented')
    }

    async signOut() {
        throw new Error('Method "signOut" should be implemented')
    }

    async fetchData(...args) {
        throw new Error('Method "fetchData" should be implemented')
    }

    async postData(...args) {
        throw new Error('Method "postData" should be implemented')
    }

    async deleteData(...args) {
        throw new Error('Method "deleteData" should be implemented')
    }

    async update(...args) {
        throw new Error('Method "update" should be implemented')
    }

}