<template>
  <div class="bg-white pt-10">
    <div class="container mx-auto px-8 overflow-y-auto">
      <p class="font-inter font-semibold text-xl">Vehicles</p>
      <div class="relative shadow-lg border sm:rounded-lg mt-10">
        <div class="flex justify-between">
           <!-- Search field -->
           <div class="relative my-5 ml-5">
            <div
              class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
            >
              <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
            </div>
            <input
              type="text"
              id="table-search"
              v-model="searchVehicleString"
              class="block ps-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search vehicle"
            />
          </div>
          <button
            class="bg-orange-600 py-2.5 px-3 my-4 mr-4 text-white rounded"
            @click="isAddVehiclePopUpOpen = true"
          >
            Add Vehicle
          </button>
          <!-- Add vehicle pop up form layout -->
          <TransitionRoot appear :show="isAddVehiclePopUpOpen" as="template">
            <Dialog
              as="div"
              @close="isAddVehiclePopUpOpen = false"
              class="relative z-10"
            >
              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div class="fixed inset-0 bg-black/25" />
              </TransitionChild>

              <div class="fixed inset-0 overflow-y-auto">
                <div
                  class="flex min-h-full items-center justify-center p-4 text-center"
                >
                  <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95"
                  >
                    <DialogPanel
                      class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                    >
                      <DialogTitle
                        as="h3"
                        class="text-lg font-medium leading-6 text-gray-900 uppercase flex justify-center"
                      >
                        New Vehicle
                      </DialogTitle>
                      <form @submit.prevent="addVehicle">
                        <div class="mb-4 font-inter mt-10">
                          <div>
                            <label
                              for="make"
                              class="block text-black text-sm mb-2"
                              >Number Plate</label
                            >
                            <input
                              v-model="newVehicleArray.NumberPlate"
                              type="text"
                              id="make"
                              name="make"
                              class="border w-full focus:outline-none focus:ring-2 focus:ring-orange-400 rounded py-1 px-2"
                              required
                            />
                          </div>
                          <div class="my-4" >
                            <label
                              for="model"
                              class="block text-black text-sm mb-2"
                              >Trailer Number Plate</label
                            >
                            <input
                              v-model="newVehicleArray.TrailerNumberPlate"
                              type="text"
                              id="model"
                              name="model"
                              class="border w-full focus:outline-none focus:ring-2 focus:ring-orange-400 rounded py-1 px-2"
                              required
                            />
                          </div>
                          <div>
                            <label
                              for="registration"
                              class="block text-black text-sm mb-2"
                              >Body Type</label
                            >
                            <input
                              v-model="newVehicleArray.BodyType"
                              type="text"
                              id="registration"
                              name="registration"
                              class="border w-full focus:outline-none focus:ring-2 focus:ring-orange-400 rounded py-1 px-2"
                              required
                            />
                          </div>
                        </div>
                       
                        <!-- cancel form input-->
                        <div class="flex justify-between mt-8">
                          <button
                            @click="isAddVehiclePopUpOpen = false"
                            class="bg-red-600 text-white py-2 px-4 rounded"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            class="bg-orange-600 text-white py-2 px-4 rounded"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </DialogPanel>
                  </TransitionChild>
                </div>
              </div>
            </Dialog>
          </TransitionRoot>
        </div>
        <div class="overflow-x-auto">
          <table
            class="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400"
          >
            <thead class="text-xs text-black uppercase bg-gray-200 font-inter">
              <tr>
                <th scope="col" class="px-6 py-3"></th>
                <th scope="col" class="px-6 py-3">Number Plate</th>
                <th scope="col" class="px-6 py-3">Trailer No</th>
                <th scope="col" class="px-6 py-3">Body Type</th>
                <th scope="col" class="px-6 py-3">Status</th>
                <th scope="col" class="px-6 py-3">Date Created</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody v-if="vehiclesList.length > 0" >
              <tr
                v-for="(vehicle, index) in vehiclesList"
                :key="index"
                class="border-b hover:bg-emerald-100 text-gray-900 font-inter"
              >
                <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4 text-sky-700">
                  {{ vehicle.NumberPlate }}
                </td>
                <td class="px-6 py-4">{{ vehicle.TrailerNumberPlate }}</td>
                <td class="px-6 py-4">{{ vehicle.BodyType }}</td>
                <td class="px-6 py-4">
                  <div
                    class="flex items-center rounded w-fit py-1 px-2.5 bg-green-700 text-white"
                    :class="
                      vehicle.VehicleStatus == 'Active'
                        ? ' bg-green-700'
                        : 'bg-red-700'
                    "
                  >
                    <p>{{ vehicle.VehicleStatus }}</p>
                  </div>
                </td>
                <td class="px-6 py-4">
                  {{
                    $store.getters.getTimestampzFormattedDate(vehicle.CreatedAt)
                  }}
                </td>
                <td class="px-6 py-4">
                  <font-awesome-icon :icon="['fas', 'trash']" class="text-red-700" @click="deleteVehicle(vehicle.VehicleID)"/>
                </td>
              </tr>
            </tbody>
            <div v-else>
              <p class="font-inter ml-5 my-5 text-gray-700" >No vehicles available</p>
            </div>
          </table>
        </div>
      </div>
    </div>
    <!-- Alert Dialog -->
    <div
        v-if="showAlertDialog"
        id="alert-2"
        class="absolute top-0 right-0 w-72 flex items-center p-4 mb-4 rounded-lg"
        :class="
          isErrorAlertDialog
            ? 'text-red-800 bg-red-50'
            : 'text-green-800 bg-green-50'
        "
        role="alert"
      >
        <svg
          class="flex-shrink-0 w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div class="ms-3 text-sm font-medium">{{ alertDialogMessage }}</div>
        <button
          type="button"
          :class="
            isErrorAlertDialog
              ? 'bg-red-50 text-red-500 focus:ring-red-400 hover:bg-red-200'
              : 'bg-green-50 text-green-500 focus:ring-green-400 hover:bg-green-200'
          "
          class="ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex items-center justify-center h-8 w-8"
          data-dismiss-target="#alert-2"
          aria-label="Close"
        >
          <span class="sr-only">Close</span>
          <svg
          @click="showAlertDialog = false"
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
  </div>
</template>
<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
</script>
<script>
import DataRepository from "@/infrastructure/services/DataRepository";
export default {
  data() {
    return {
      isAddVehiclePopUpOpen: false,
      newVehicleArray: {
        NumberPlate: '',
        TrailerNumberPlate: '',
        BodyType: '',
      },
      showAlertDialog: false,
      alertDialogMessage: '',
      isErrorAlertDialog: false,
      searchVehicleString: '',
    };
  },
  computed: {
    vehiclesList() {
      const filteredVehiclesList = this.searchVehicleString == '' ? this.$store.state.vehiclesArrayList : 
      this.$store.state.vehiclesArrayList.filter((vehicle) => {
        return vehicle.NumberPlate.toLowerCase().indexOf(this.searchVehicleString) > -1;
      })
      return filteredVehiclesList
    },
  },
  methods: {
    async addVehicle(){
      const response = await DataRepository.addNewVehicle(this.newVehicleArray)
      if(response.success && !response.error){
        this.showAlertDialog = true
        this.alertDialogMessage = 'Vehicle saved'
        this.isErrorAlertDialog = false
        this.isAddVehiclePopUpOpen = false
      }else{
        this.showAlertDialog = true
        this.alertDialogMessage = `Failed! Vehicle not saved. ${response.error}`
        this.isErrorAlertDialog = true
        this.isAddVehiclePopUpOpen = false
      }

      //reload vehicles list
      this.$store.dispatch("fetchVehicles");
      //dismiss alert dialog after 1000ms
      setTimeout(()=>{this.showAlertDialog = false},3000)
    },

    async deleteVehicle(vehicleId){
      const response = await DataRepository.deleteVehicle(vehicleId)
      if(!response.error){
        this.showAlertDialog = true
        this.alertDialogMessage = 'Vehicle deleted'
        this.isErrorAlertDialog = false
        this.isAddVehiclePopUpOpen = false
      }else{
        this.showAlertDialog = true
        this.alertDialogMessage = `Failed! Vehicle not deleted. ${response.error}`
        this.isErrorAlertDialog = true
        this.isAddVehiclePopUpOpen = false
      }

      //reload vehicles list
      this.$store.dispatch("fetchVehicles");
      //dismiss alert dialog after 1000ms
      setTimeout(()=>{this.showAlertDialog = false},3000)
    }
  },
  mounted() {
    this.$store.dispatch("fetchVehicles");
  },
};
</script>
