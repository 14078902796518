import { authenticated } from "../decorators/decorator";

export default class DataProxy {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async login(...args) {
        return await this.adapter.login(...args)
    }

    @authenticated
    async fetchData(...args) {
        return await this.adapter.fetchData(...args)
    }

    @authenticated
    async signOut() {
        return await this.adapter.signOut()
    }
    
    async getUserSession() {
        return await this.adapter.getUserSession()
    }

    async postData(...args) {
        return await this.adapter.postData(...args)
    }

    @authenticated
    async update(...args) {
        return await this.adapter.update(...args)
    }
}