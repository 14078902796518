import SupabaseAdapter from "../adapters/SupabaseAdapter";
import { authenticated, logger } from "../decorators/decorator";
import DataProxy from "./DataProxy";
import store from "@/store";

class DataRepository {
  constructor() {
    const adapter = new SupabaseAdapter();
    this.service = new DataProxy(adapter);
  }

  @logger
  loginUser(email, password) {
    return this.service
      .login({ email: email, password: password })
      .then((response) => {
        if (response.success.user != null) {
          store.commit("updateUserData", response.success.user);
        }

        if (response.success.session != null) {
          store.commit("updateUserSession", response.success.session);
          store.commit("updateLoginState", true);
        }
        return response;
      });
  }

  @logger
  @authenticated
  async fetchVehicles() {
    const response = await this.service.fetchData({
      table: "Vehicles",
      filters: [
        { column: "VehicleStatus", operator: "eq", value: "Active" },
        { column: "Deleted", operator: "eq", value: "false" },
      ],
      orderBy: {
        column: 'CreatedAt',
        ascending: false
      }
    });

    return response;
  }

  @logger
  signOutUser() {
    return this.service.signOut().then((response) => {
      if (response.error == null) {
        store.commit("updateUserSession", null);
        store.commit("updateUserData", null);
        store.commit("updateLoginState", false);
      }
      return response;
    });
  }

  @logger
  getUserSession() {
    return this.service.getUserSession().then((response) => {
      if (response.success.session) {
        store.commit("updateUserSession", response.success.session);
        store.commit("updateUserData", response.success.session.user);
        store.commit("updateLoginState", true);
      }

      return response;
    });
  }

  @logger
  @authenticated
  async fetchVehicleIssues(startDate = null) {
    if (startDate) {
      const response = await this.service.fetchData({
        table: "VehicleIssues",
        column: "*, VehicleID(*)",
        filters: [
          {
            column: "DateReported",
            operator: "gte",
            value: startDate.toISOString(),
          },
        ],
        orderBy: {
          column: 'DateReported',
          ascending: false
        }
      });

      return response;
    } else {
      const response = await this.service.fetchData({
        table: "VehicleIssues",
        column: "*, VehicleID(*)",
        orderBy: {
          column: 'DateReported',
          ascending: false
        }
      });

      return response;
    }
  }

  @logger
  @authenticated
  async fetchVehicleInspections(startDate = null) {
    if (startDate) {
      const response = await this.service.fetchData({
        table: "VehicleInspections",
        column: "*, VehicleID(*), InspectionDetails:VehicleInspectionID(CheckID(Check), InspectionStatus, Comments)",
        filters: [{ column: "CreatedAt", operator: "gte", value: startDate }],
        orderBy: {
          column: 'CreatedAt',
          ascending: false
        }
      });

      return response;
    } else {
      const response = await this.service.fetchData({
        table: "VehicleInspections",
        column: "*, VehicleID(*), InspectionDetails:VehicleInspectionID(CheckID(Check), InspectionStatus, Comments)",
        orderBy: {
          column: 'CreatedAt',
          ascending: false
        }
      });

      return response;
    }
  }

  @logger
  @authenticated
  async addNewVehicle(vehicleArray) {
    const response = await this.service.postData({
      table: "Vehicles",
      data: vehicleArray,
    });
    return response;
  }

  @logger
  async deleteVehicle(vehicleId) {
    const response = await this.service.update({
      table: "Vehicles",
      data: { Deleted: "TRUE" },
      primaryKey: "VehicleID",
      id: vehicleId,
    });
    return response;
  }

  @logger
  @authenticated
  async fetchVehicleChecks() {
    const response = await this.service.fetchData({
      table: "VehicleChecks",
      filters: [{ column: "Deleted", operator: "eq", value: "False" }],
      orderBy: {
        column: 'CreatedAt',
        ascending: false
      }
    });

    return response;
  }

  @logger
  @authenticated
  async addVehicleCheck(checksArray) {
    const response = await this.service.postData({
      table: "VehicleChecks",
      data: checksArray,
    });
    return response;
  }

  @logger
  async deleteVehicleCheck(checkId) {
    const response = await this.service.update({
      table: "VehicleChecks",
      data: { Deleted: "TRUE" },
      primaryKey: "CheckID",
      id: checkId,
    });
    return response;
  }

  @logger
  @authenticated
  async fetchVehicleInspectionDetails(vehicleInspectionID){
    const response = await this.service.fetchData({
      table: "InspectionDetails",
      column: '*,CheckID(*)',
      filters: [{ column: "VehicleInspectionID", operator: "eq", value: vehicleInspectionID }],
    });

    return response
  }

  @logger
  @authenticated
  async fetchVehicleFuelReports() {
    const response = await this.service.fetchData({
      table: "VehicleFuelRecords",
      column: "*, VehicleID(*)",
      orderBy: {
        column: 'CreatedAt',
        ascending: false
      }
    });

    return response;
  }

  @logger
  @authenticated
  async closeVehicleIssue(vehicleIssueJsonData, vehicleIssueID){
    const response = await this.service.update({table: 'VehicleIssues', data: vehicleIssueJsonData, primaryKey: 'IssueID', id: vehicleIssueID})

    return response;
  }

  @logger
  @authenticated
  async editVehicleCheck(vehicleCheckJson, checkId){
    const response = await this.service.update({table: 'VehicleChecks', data: vehicleCheckJson, primaryKey: 'CheckID', id: checkId})
    return response
  }
}
export default new DataRepository();
