<template>
  <div class="bg-white pt-10">
    <div class="container mx-auto px-8 overflow-y-auto">
      <p class="font-inter font-semibold text-xl">Daily Vehicle Inspections</p>
      <div class="relative shadow-lg border sm:rounded-lg mt-10">
        <div class="overflow-x-auto">
          <div class="flex justify-between items-center mx-5">
            <!-- Search field -->
            <div class="relative my-5 ml-5">
              <div
                class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
              >
                <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
              </div>
              <input
                type="text"
                id="table-search"
                v-model="searchVehicleString"
                class="block ps-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search vehicle"
              />
            </div>
            <!-- Download report button -->
            <div>
              <p
                @click="downloadVehicleInspectionsCSVReport"
                class="font-inter hover:text-orange-400 text-sm font-semibold cursor-pointer"
              >
                Download Report
              </p>
            </div>
          </div>

          <table
            class="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400"
          >
            <thead class="text-xs text-black uppercase bg-gray-200 font-inter">
              <tr>
                <th scope="col" class="px-6 py-3"></th>
                <th scope="col" class="px-6 py-3">Vehicle</th>
                <th scope="col" class="px-6 py-3">Odometer Reading</th>
                <th scope="col" class="px-6 py-3">Status</th>
                <th scope="col" class="px-6 py-3">Inspection Date</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody v-if="vehicleInspectionsList.length > 0">
              <tr
                v-for="(inspectionItem, index) in vehicleInspectionsList"
                :key="index"
                class="border-b hover:bg-emerald-100 text-gray-900 font-inter"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium whitespace-nowrap border-r"
                >
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4 border-r text-sky-800">
                  {{ inspectionItem.VehicleID.NumberPlate }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ inspectionItem.OdometerReading.toLocaleString() }}
                </td>
                <td class="px-6 py-4 border-r">
                  <div class="flex items-center">
                    <div
                      class="h-2 w-2 rounded-full"
                      :class="
                        inspectionItem.InspectionStatus != 'Pass'
                          ? 'bg-red-700'
                          : 'bg-green-700'
                      "
                    ></div>
                    <p class="ml-2">{{ inspectionItem.InspectionStatus }}</p>
                  </div>
                </td>
                <td class="px-6 py-4 border-r">
                  {{
                    $store.getters.getTimestampzFormattedDate(
                      inspectionItem.CreatedAt
                    )
                  }}
                </td>
                <td class="px-6 py-4">
                  <VehicleInspectionDetailsComponent
                    :inspectionItem="inspectionItem"
                  />
                </td>
              </tr>
            </tbody>
            <div v-else>
              <p class="font-inter ml-5 my-5 text-gray-700">
                No inspections found
              </p>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataRepository from "@/infrastructure/services/DataRepository";
import VehicleInspectionDetailsComponent from "../components/VehicleInspectionDetails.vue";
export default {
  components: {
    VehicleInspectionDetailsComponent,
  },
  data() {
    return {
      vehicleInspectionsArrayList: [],
      searchVehicleString: "",
    };
  },
  computed: {
    vehicleInspectionsList() {
      const filteredVehiclesInspectionsList =
        this.searchVehicleString == ""
          ? this.vehicleInspectionsArrayList
          : this.vehicleInspectionsArrayList.filter((inspectionItem) => {
              return (
                inspectionItem.VehicleID.NumberPlate.toLowerCase().indexOf(
                  this.searchVehicleString.toLowerCase()
                ) > -1
              );
            });
      return filteredVehiclesInspectionsList;
    },
  },
  methods: {
    async fetchVehicleInspections() {
      const result = await DataRepository.fetchVehicleInspections();
      if (result.success && !result.error) {
        this.vehicleInspectionsArrayList = result.success;
      }
    },

    downloadVehicleInspectionsCSVReport() {
      let csvReportContent = "";

      for (const inspection of this.vehicleInspectionsArrayList) {
        // set inspection details overview as first row
        csvReportContent += `${inspection.VehicleID.NumberPlate},${
          inspection.OdometerReading
        },${
          inspection.InspectionStatus
        },${this.$store.getters.getTimestampzFormattedDate(
          inspection.CreatedAt
        )}\n\n`;
        // Loop through vehicle inspection details and create csv string
        const inspectionCheckDetailsArray = inspection.InspectionDetails.map(
          (check) => ({
            CHECK: check.CheckID.Check,
            STATUS: check.InspectionStatus,
            COMMENTS: check.Comments,
          })
        );
        csvReportContent += `${this.convertToCSV(
          inspectionCheckDetailsArray
        )}\n\n`;
      }

      const blob = new Blob([csvReportContent], {
        type: "text/csv;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Vehicle_Inspections_Report_${new Date()
          .toISOString()
          .slice(0, 10)}.csv`
      );
      link.click();
    },

    convertToCSV(dataArray) {
      const headers = Object.keys(dataArray[0]);
      const rows = dataArray.map((obj) => headers.map((header) => obj[header]));
      const headerRows = headers.join(",");
      const csvRows = [
        headerRows,
        ...rows.map((row) =>
          row
            .map((value) => {
              if (typeof value === "string" && value.includes(",")) {
                return `"${value}"`; // Wrap text containing commas in double quotes
              }
              return value;
            })
            .join(",")
        ),
      ];
      return csvRows.join("\n");
    },
  },
  mounted() {
    this.fetchVehicleInspections();
  },
};
</script>
