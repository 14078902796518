import VehicleInspectionsView from "./views/VehicleInspectionsView.vue";

const authRoutes = [
    {
        path: '/inspections',
        component: VehicleInspectionsView,
        name: 'Inspections',
        meta: { requiresAuth: true },

    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}