import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import tailwind
import '@/index.css'
// Font awesome library
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
// don't delete
import { registerModules } from './register-feature-modules'
//import feature modules
import AuthenticationFeatureModule from '@/feature-modules/Authentication'
import DashboardFeatureModule from '@/feature-modules/Dashboard'
import VehicleCheckFeatureModule from '@/feature-modules/VehicleChecks'
import VehicleIssuesFeatureModule from '@/feature-modules/Issues'
import VehiclesFeatureModule from '@/feature-modules/Vehicles'
import VehicleInspectionsFeatureModule from "./feature-modules/VehicleInspections";
import VehicleFuelingFeatureModule from '@/feature-modules/VehicleFueling'

registerModules({
    authenticationFeatureModule: AuthenticationFeatureModule,
    dashboardFeatureModule: DashboardFeatureModule,
    vehicleChecksFeatureModule: VehicleCheckFeatureModule,
    vehicleIssueFeatureModule: VehicleIssuesFeatureModule,
    vehiclesFeatureModule: VehiclesFeatureModule,
    vehicleInspectionsFeatureModule: VehicleInspectionsFeatureModule,
    vehicleFuelingFeatureModule: VehicleFuelingFeatureModule,
  })

  library.add(fas)

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
