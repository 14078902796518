import { createStore } from "vuex";
import DataRepository from "@/infrastructure/services/DataRepository.js";

export default createStore({
  state: {
    loggedIn: false,
    redirectionUrl: "/",
    session: null,
    user: null,
    vehiclesArrayList: [],
  },
  getters: {
    getUserData(state) {
      return state.user;
    },
    getTimestampzFormattedDate: () => (timestamp) => {
      const dateObject = new Date(timestamp);

      // Get individual values from date
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
      const day = String(dateObject.getDate()).padStart(2, '0');
      const hours = String(dateObject.getHours()).padStart(2, '0');
      const minutes = String(dateObject.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    getCurrentTimestampZDate(){
      return new Date().toISOString().slice(0, 19).replace('T', ' ') + 'Z';
    }
  },
  mutations: {
    updateRedirection(state, path) {
      state.redirectionUrl = path;
    },
    updateLoginState(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    updateUserData(state, user) {
      state.user = user;
    },
    updateUserSession(state, session) {
      state.session = session;
    },
    updateToken(state, token) {
      state.token = token;
    },
    setVehiclesList(state, vehiclesList) {
      state.vehiclesArrayList = vehiclesList;
    },
    
  },
  actions: {
    async fetchVehicles({ commit }) {
      const result = await DataRepository.fetchVehicles();
      if (result.success && !result.error) {
        commit("setVehiclesList", result.success);
      }
    },
  },
  modules: {},
});
