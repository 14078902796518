<template>
    <div @click="openModal">
        <img :src="imageUrl" class="h-20 w-20"/>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-10">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/25" />
        </TransitionChild>
  
        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform overflow-hidden rounded-md bg-white p-4 text-left align-middle shadow-xl transition-all"
              >
                <DialogTitle as="h3">
                  <div class="grid place-items-end">
                    <font-awesome-icon
                      :icon="['fas', 'xmark']"
                      class="h-6 w-6 text-gray-400"
                      @click="closeModal"
                    />
                  </div>
  
                  <div class="grid justify-items-center font-inter leading-6">
                    <p class="text-xl font-semibold text-orange-600">
                      Fuel Pump Image
                    </p>
                  </div>
                </DialogTitle>
                <div class="mt-5 border p-4">
                  <div class="font-inter mt-3">
                    <a :href="imageUrl" target="_blank" class="flex justify-end outline-none text-sm font-inter my-2">Download Image</a>
                    <img :src="imageUrl" class="w-full h-96 object-cover">
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  } from "@headlessui/vue";
  
  const isOpen = ref(false);
  
  function closeModal() {
    isOpen.value = false;
  }
  function openModal() {
    isOpen.value = true;
  }
  </script>
  <script>
  export default {
    props: {
      imageUrl: {
        required: true,
        default: '',
        type: String
      },
    },
  };
  </script>
  