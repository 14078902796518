<template>
    <header class="">
      <nav
        class="mx-auto flex items-center justify-between p-6"
      >
        <RouterLink to="/">
          <img src="@/assets/images/navi-logo.png" class="h-8">
        </RouterLink>
      </nav>
    </header>
  </template>
  
  