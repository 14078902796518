<template>
  <div class="bg-white pt-10">
    <div class="container mx-auto px-8 overflow-y-auto">
      <p class="font-inter font-semibold text-xl">Dashboard</p>
      <!-- Stats section -->
      <div class="grid grid-cols-3 mt-3 space-x-2">
        <!-- Tile one -->
        <div
          class="bg-emerald-100 grid justify-items-center py-3 rounded border border-emerald-800"
        >
          <div
            class="bg-emerald-800 rounded-full py-3 px-3 grid justify-items-center"
          >
            <font-awesome-icon
              :icon="['fas', 'magnifying-glass']"
              class="text-white w-3 h-3"
            />
          </div>
          <p class="font-inter font-semibold text-xl text-emerald-800 mt-3">
            {{ vehicleDailyInspectionsList.length }}
          </p>
          <p class="font-inter text-xs text-emerald-600">Inspections</p>
        </div>
        <!-- Tile two -->
        <div
          class="bg-pink-100 grid justify-items-center py-3 rounded border border-pink-400"
        >
          <div
            class="bg-pink-600 rounded-full py-3 px-3 grid justify-items-center"
          >
            <font-awesome-icon
              :icon="['fas', 'triangle-exclamation']"
              class="text-white w-3 h-3"
            />
          </div>
          <p class="font-inter font-semibold text-xl text-pink-600 mt-3">
            {{ vehicleIssues.length }}
          </p>
          <p class="font-inter text-xs text-pink-600">Issues Reported</p>
        </div>
        <!-- Tile three -->
        <div
          class="bg-blue-100 grid justify-items-center py-3 rounded border border-blue-800"
        >
          <div
            class="bg-blue-800 rounded-full py-3 px-3 grid justify-items-center"
          >
            <font-awesome-icon
              :icon="['fas', 'car']"
              class="text-white w-3 h-3"
            />
          </div>
          <p class="font-inter font-semibold text-xl text-blue-800 mt-3">
            {{ numberOfActiveVehicles ? numberOfActiveVehicles : "NaN" }}
          </p>
          <p class="font-inter text-xs text-blue-400">Active Vehicles</p>
        </div>
      </div>
      <!-- Vehicle Issue Summary -->
      <div class="my-10">
        <div class="flex items-center space-x-2 mb-5">
          <font-awesome-icon
            :icon="['fas', 'triangle-exclamation']"
            class="text-pink-600"
          />
          <p class="font-inter font-semibold text-sm">Vehicle Issues</p>
        </div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-96">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-black uppercase bg-gray-200 font-inter">
              <tr>
                <th scope="col" class="px-6 py-3 border-r border-gray-300">
                  Vehicle
                </th>
                <th scope="col" class="px-6 py-3 border-r border-gray-300">
                  Summary
                </th>
                <th scope="col" class="px-6 py-3 border-r border-gray-300">
                  Status
                </th>
                <th scope="col" class="px-6 py-3 border-r border-gray-300">
                  Reporting Date
                </th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody v-if="vehicleIssues.length > 0">
              <tr
                v-for="(issue, index) in vehicleIssues"
                :key="index"
                class="border-b hover:bg-pink-100 text-gray-900 font-inter"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium whitespace-nowrap border-r"
                >
                  {{ issue.VehicleID.NumberPlate }}
                </th>
                <td class="px-6 py-4 border-r">{{ issue.IssueSummary }}</td>
                <td class="px-6 py-4 border-r">
                  <div class="flex items-center">
                    <div
                      class="h-2 w-2 rounded-full"
                      :class="
                        issue.IssueStatus == 'Open'
                          ? 'bg-red-700'
                          : 'bg-green-700'
                      "
                    ></div>
                    <p class="ml-2">{{ issue.IssueStatus }}</p>
                  </div>
                </td>
                <td class="px-6 py-4 border-r">{{ $store.getters.getTimestampzFormattedDate(issue.DateReported) }}</td>
                <td class="px-6 py-4">
                  <VehicleIssueDetailsComponent :issue="issue"/>
                </td>
              </tr>
            </tbody>
            <div v-else class="flex items-center py-2 px-3">
              <p>No vehicle issues reported in the past week</p>
            </div>
          </table>
        </div>
      </div>
      <!-- Vehicle Inspections Summary -->
      <div class="my-10">
        <div class="flex items-center space-x-2 mb-5">
          <font-awesome-icon
            :icon="['fas', 'magnifying-glass']"
            class="text-emerald-800"
          />
          <p class="font-inter font-semibold text-sm">Vehicle Inspections</p>
        </div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg max-h-96">
          <table
            class="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400"
          >
            <thead class="text-xs text-black uppercase bg-gray-200 font-inter">
              <tr>
                <th scope="col" class="px-6 py-3">Vehicle</th>
                <th scope="col" class="px-6 py-3">Date</th>
                <th scope="col" class="px-6 py-3">Odometer</th>
                <th scope="col" class="px-6 py-3">Status</th>
                <th scope="col" class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody v-if="vehicleDailyInspectionsList.length > 0">
              <tr
                v-for="(inspection, index) in vehicleDailyInspectionsList"
                :key="index"
                class="border-b hover:bg-emerald-100 text-gray-900 font-inter"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium whitespace-nowrap text-sky-700"
                >
                  {{ inspection.VehicleID.NumberPlate }}
                </th>
                <td class="px-6 py-4">{{ $store.getters.getTimestampzFormattedDate(inspection.CreatedAt) }}</td>
                <td class="px-6 py-4">{{ inspection.OdometerReading.toLocaleString() }}</td>
                <td class="px-6 py-4">
                  <div
                    :class="
                      inspection.InspectionStatus == 'Pass'
                        ? 'bg-green-200'
                        : 'bg-red-200'
                    "
                    class="flex items-center rounded-3xl w-fit py-1 px-2.5"
                  >
                    <p
                      :class="
                        inspection.InspectionStatus == 'Pass'
                          ? 'text-green-700'
                          : 'text-red-700'
                      "
                    >
                      {{ inspection.InspectionStatus }}
                    </p>
                  </div>
                </td>
                <td class="px-6 py-4">
                  <VehicleInspectionDetailsComponent :inspectionItem="inspection"/>
                </td>
              </tr>
            </tbody>
            <div v-else class="flex items-center py-2 px-3" ><p>No vehicle inspections done today</p></div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataRepository from "@/infrastructure/services/DataRepository.js";
import VehicleInspectionDetailsComponent from '@/feature-modules/VehicleInspections/components/VehicleInspectionDetails.vue';
import VehicleIssueDetailsComponent from '@/feature-modules/Issues/components/VehicleIssueDetailsComponent.vue'
export default {
  components:{
    VehicleInspectionDetailsComponent,
    VehicleIssueDetailsComponent
  },
  data() {
    return {
      numberOfActiveVehicles: null,
      vehicleIssuesArray: [],
      vehicleInspectionsArray: [],
    };
  },
  computed: {
    vehicleIssues() {
      return this.vehicleIssuesArray;
    },
    vehicleDailyInspectionsList() {
      return this.vehicleInspectionsArray;
    },
  },
  methods: {
    async fetchVehicles() {
      const response = await DataRepository.fetchVehicles();
      if (response.success) {
        this.numberOfActiveVehicles = response.success.length;
      }
    },

    async fetchDrivers() {
      const response = await DataRepository.fetchVehicles();
      if (response.success) {
        this.numberOfActiveVehicles = response.success.length;
      }
    },

    async fetchPastWeekVehicleIssue() {
      const startDate = new Date();
      // set hours to 12 am to fetch from start of the day
      startDate.setHours(0,0,0,0)
      //set start date to 1 week before
      startDate.setDate(startDate.getDate() - 7);

      const response = await DataRepository.fetchVehicleIssues(startDate);
      if (response.success) {
        this.vehicleIssuesArray = response.success;
      }
    },

    async fetchDailyVehicleInspections() {
      //get current date from 12 am
      const startDate = new Date();
      startDate.setHours(0,0,0,0)
      const response = await DataRepository.fetchVehicleInspections(startDate.toISOString());
      if (response.success) {
        this.vehicleInspectionsArray = response.success;
      }
    },

    formatDate(date) {
      const dateObject = new Date(date);

      //get individual values from date
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1; // Month is 0-indexed, so add 1
      const day = dateObject.getDate();
      const hours = dateObject.getHours();
      const minutes = dateObject.getMinutes();

      return `${year}-${month}-${day} ${hours}:${minutes}`
    },
  },
  mounted() {
    this.fetchVehicles();
    this.fetchPastWeekVehicleIssue();
    this.fetchDailyVehicleInspections();
  },
};
</script>
