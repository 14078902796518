import LoginView from "./views/LoginView.vue";

const authRoutes = [
    {
        path: '/login',
        component: LoginView,
        name: 'Login',
        meta: {
            showSidePanelMenu: false
        }

    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}