<template>
  <div class="w-full h-screen bg-slate-100 px-4 py-10 text-sm">
    <router-link to="/">
      <div
        class="flex justify-start items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'Dashboard'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'Dashboard'"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="h-4 w-4"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.75 6A2.25 2.25 0 0 1 6 3.75h2.25A2.25 2.25 0 0 1 10.5 6v2.25a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V6ZM3.75 15.75A2.25 2.25 0 0 1 6 13.5h2.25a2.25 2.25 0 0 1 2.25 2.25V18a2.25 2.25 0 0 1-2.25 2.25H6A2.25 2.25 0 0 1 3.75 18v-2.25ZM13.5 6a2.25 2.25 0 0 1 2.25-2.25H18A2.25 2.25 0 0 1 20.25 6v2.25A2.25 2.25 0 0 1 18 10.5h-2.25a2.25 2.25 0 0 1-2.25-2.25V6ZM13.5 15.75a2.25 2.25 0 0 1 2.25-2.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-2.25A2.25 2.25 0 0 1 13.5 18v-2.25Z"
          />
        </svg>

        <p class="font-inter ml-2">Dashboard</p>
      </div>
    </router-link>
    <router-link to="/vehicles">
      <div
        class="flex items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'Vehicles'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'Vehicles'"
      >
        <font-awesome-icon :icon="['fas', 'car']" class="w-3 h-3" />
        <p class="font-inter ml-2">Vehicles</p>
      </div>
    </router-link>
    <router-link to="/checks">
      <div
        class="flex items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'Checks'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'Checks'"
      >
        <font-awesome-icon :icon="['fas', 'list-check']" class="w-4 h-4" />
        <p class="font-inter ml-2">Inspection Checks</p>
      </div>
    </router-link>
    <router-link to="/inspections">
      <div
        class="flex items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'Inspections'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'Inspections'"
      >
        <font-awesome-icon
          :icon="['fas', 'magnifying-glass']"
          class="w-4 h-4"
        />
        <p class="font-inter ml-2">Vehicle Inspections</p>
      </div>
    </router-link>
    <router-link to="/issues">
      <div
        class="flex items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'Issues'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'Issues'"
      >
        <font-awesome-icon
          :icon="['fas', 'triangle-exclamation']"
          class="w-4 h-4"
        />
        <p class="font-inter ml-2">Vehicle Issues</p>
      </div>
    </router-link>
    <router-link to="/fuel-report">
      <div
        class="flex items-center py-2.5 px-2.5 rounded"
        :class="
          activePage == 'FuelReport'
            ? 'bg-orange-600 text-white'
            : 'text-black bg-none'
        "
        @click="activePage = 'FuelReport'"
      >
      <font-awesome-icon :icon="['fas', 'gas-pump']" class="w-4 h-4"/>
        <p class="font-inter ml-2">Fuel Report</p>
      </div>
    </router-link>
    <hr class="my-5" />
    <!-- Log out -->
    <router-link to="/checks">
      <div
        class="flex items-center py-2.5 px-2.5 rounded text-red-600"
        @click="logOut"
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-right-from-bracket']"
          class="w-4 h-4"
        />
        <p class="font-inter ml-2">Log Out</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import DataRepository from "@/infrastructure/services/DataRepository";
export default {
  data() {
    return {
      activePage: "Dashboard",
    };
  },
  methods: {
    async logOut() {
      await DataRepository.signOutUser();
      this.$router.push("/login");
    },
  },
  mounted() {
    //check router name and update active page to update the selected menu item
    this.$router.beforeEach((to, from, next) => {
      if (to) {
        this.activePage = to.name;
      }
      next();
    });
  },
};
</script>
