import { createClient } from "@supabase/supabase-js";
import IDataService from "../interfaces/IDataService";
import ApiResponseModel from "@/models/ApiResponseModel";

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;

let supabaseInstance = createClient(supabaseUrl, supabaseKey);

export default class SupabaseAdapter extends IDataService {
  constructor() {
    super();
    if (supabaseInstance) {
      this.client = supabaseInstance;
    } else {
      this.client = createClient(supabaseUrl, supabaseKey);
    }
  }

  async login({ email, password }) {
    try {
      const authResponse = await this.client.auth.signInWithPassword({
        email: email,
        password: password,
      });
      return new ApiResponseModel(authResponse);
    } catch (error) {
      return new ApiResponseModel({ error: error });
    }
  }

  async getUserSession() {
    try {
      const authResponse = await this.client.auth.getSession();
      return new ApiResponseModel(authResponse);
    } catch (error) {
      return new ApiResponseModel({ error: error });
    }
  }

  async signOut() {
    try {
      const authResponse = await this.client.auth.signOut();
      return new ApiResponseModel(authResponse);
    } catch (error) {
      return new ApiResponseModel({ error: error });
    }
  }

  async fetchData(
    { schema, table, column, filters, orderBy } = {
      schema: null,
      column: null,
      filters: null,
      orderBy: null,
    }
  ) {
    let tableSchema = "";
    if (schema == null) {
      tableSchema = "public";
    } else {
      tableSchema = schema;
    }

    if (column === null) {
      const response = await this.client
        .schema(tableSchema)
        .from(table)
        .select();
      return new ApiResponseModel(response);
    }

    let request = this.client.schema(tableSchema).from(table).select(column);

    if (filters) {
      for (const filter of filters) {
        request = request.filter(filter.column, filter.operator, filter.value);
      }
    }

    if (orderBy) {
      const { column, ascending } = orderBy;
      request = request.order(column, { ascending });
    }

    const response = await request;
    return new ApiResponseModel(response);
  }

  async postData({ table, data }) {
    try {
      const response = await this.client.from(table).insert(data).select();
      return new ApiResponseModel(response);
    } catch (e) {
      return ApiResponseModel({ error: e });
    }
  }

  async update({table, data, primaryKey, id}) {
    const response = await this.client.from(table)
        .update(data)
        .eq(primaryKey, id)
        .select();
    return new ApiResponseModel(response);
}
}
