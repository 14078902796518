import DashboardView from "./views/DashboardView.vue";

const authRoutes = [
    {
        path: '/',
        component: DashboardView,
        name: 'Dashboard',
        meta: { requiresAuth: true },

    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}