<template>
  <div class="container">
    <!-- Nav bar component -->
    <Navbar class="sticky top-0 z-10 bg-white" />
    <!-- Main content screen. Side bar is fixed to not move -->
    <div class="container flex relative">
      <aside
        class="sticky top-0 col-span-3 h-[100vh] justify-center bg-slate-100"
        :class="showSidePanelMenu ? 'block w-3/12' : 'hidden'"
      >
        <SideDashboardMenuComponent />
      </aside>
      <router-view
        class="h-full"
        :class="showSidePanelMenu ? 'w-9/12' : 'w-full'"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/NavBar.vue";
import SideDashboardMenuComponent from "@/components/SideDashboardMenuComponent.vue";
import DataRepository from "./infrastructure/services/DataRepository";
export default {
  components: {
    Navbar,
    SideDashboardMenuComponent,
  },
  data() {
    return {
      showSidePanelMenu: true,
      sessionRecoveryTrials: 0,
    };
  },
  methods: {
    async getUserSession() {
      if (this.sessionRecoveryTrials < 1) {
        await DataRepository.getUserSession();
      }
      this.sessionRecoveryTrials += 1;
    },
  },
  mounted() {
    this.$router.beforeEach(async (to, from, next) => {
      //Get user session if already logged in
      await this.getUserSession();

      //check if route requires authentication
      if (to.meta.requiresAuth) {
        //check if user session is null
        if (!this.$store.state.session) {
          this.$store.state.redirectionUrl = to.path;
          next("/login");
          return;
        }
      }

      //check if router to has meta tag
      this.showSidePanelMenu =
        to.meta.showSidePanelMenu == undefined ? true : to.showSidePanelMenu;
      next();
    });
  },
};
</script>
