<template>
  <div class="bg-white pt-10">
    <div class="container mx-auto px-8 overflow-y-auto">
      <p class="font-inter font-semibold text-xl">Vehicles Fuel Report</p>
      <div class="relative shadow-lg border sm:rounded-lg mt-10">
        <div class="overflow-x-auto">
          <div class="flex justify-between items-center mx-5">
            <!-- Search field -->
            <div class="relative my-5">
              <div
                class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
              >
                <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
              </div>
              <input
                type="text"
                id="table-search"
                v-model="searchVehicleString"
                class="block ps-10 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search vehicle"
              />
            </div>
            <!-- Download report button -->
            <div>
              <p
                @click="downloadVehicleFuelingCSVReport"
                class="font-inter hover:text-orange-400 text-sm font-semibold cursor-pointer"
              >
                Download Report
              </p>
            </div>
          </div>

          <table
            class="w-full text-sm text-left rtl:text-right text-black dark:text-gray-400"
          >
            <thead class="text-xs text-black uppercase bg-gray-200 font-inter">
              <tr>
                <th scope="col" class="px-6 py-3"></th>
                <th scope="col" class="px-6 py-3">Vehicle</th>
                <th scope="col" class="px-6 py-3">Odometer Reading</th>
                <th scope="col" class="px-6 py-3">Fuel Added (liters)</th>
                <th scope="col" class="px-6 py-3">Currency</th>
                <th scope="col" class="px-6 py-3">Price Per Liter</th>
                <th scope="col" class="px-6 py-3">Total</th>
                <th scope="col" class="px-6 py-3">Fuel Pump Image</th>
                <th scope="col" class="px-6 py-3">Date</th>
              </tr>
            </thead>
            <tbody v-if="fuelingReportList.length > 0">
              <tr
                v-for="(fuelReport, index) in fuelingReportList"
                :key="index"
                class="border-b hover:bg-emerald-100 text-gray-900 font-inter"
              >
                <th
                  scope="row"
                  class="px-6 py-4 font-medium whitespace-nowrap border-r"
                >
                  {{ index + 1 }}
                </th>
                <td class="px-6 py-4 border-r text-sky-800">
                  {{ fuelReport.VehicleID.NumberPlate }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ fuelReport.OdometerReading.toLocaleString() }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ fuelReport.FueledLiters.toLocaleString() }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ fuelReport.Currency }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ fuelReport.PricePerLiter.toLocaleString() }}
                </td>
                <td class="px-6 py-4 border-r">
                  {{ fuelReport.TotalFuelAmount.toLocaleString() }}
                </td>
                <td class="px-6 py-4 border-r">
                  <FuelPumpImageComponent
                    v-if="fuelReport.FuelPumpImageUrl"
                    :imageUrl="getFileExtension(fuelReport.FuelPumpImageUrl) == '.heif' || 'heic' ? heicToJpg(fuelReport.FuelPumpImageUrl) : fuelReport.FuelPumpImageUrl"
                  />
                  <p v-else class="font-inter text-xs">N/A</p>
                </td>
                <td class="px-6 py-4 border-r">
                  {{
                    $store.getters.getTimestampzFormattedDate(
                      fuelReport.CreatedAt
                    )
                  }}
                </td>
              </tr>
            </tbody>
            <div v-else>
              <p class="font-inter ml-5 my-5 text-gray-700">
                No fuel reports found
              </p>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataRepository from "@/infrastructure/services/DataRepository";
import FuelPumpImageComponent from "../components/FuelPumpImageComponent.vue";
import heic2any from "heic2any";
export default {
  components: {
    FuelPumpImageComponent,
  },
  data() {
    return {
      vehicleFuelingReportArrayList: [],
      searchVehicleString: "",
    };
  },
  computed: {
    fuelingReportList() {
      const filteredFuelingReportList =
        this.searchVehicleString == ""
          ? this.vehicleFuelingReportArrayList
          : this.vehicleFuelingReportArrayList.filter((fuelReportItem) => {
              return (
                fuelReportItem.VehicleID.NumberPlate.toLowerCase().indexOf(
                  this.searchVehicleString
                ) > -1
              );
            });
      return filteredFuelingReportList;
    },
  },
  methods: {
    async fetchVehicleFuelReports() {
      const result = await DataRepository.fetchVehicleFuelReports();
      if (result.success && !result.error) {
        this.vehicleFuelingReportArrayList = result.success;
      }
    },
    downloadVehicleFuelingCSVReport() {
      // Create custom array for report with the necessary fields
      const newVehicleFuelingReportArray =
        this.vehicleFuelingReportArrayList.map((fuelRecord) => ({
          ID: fuelRecord.id,
          VEHICLE: fuelRecord.VehicleID.NumberPlate,
          "ODOMETER READING": fuelRecord.OdometerReading,
          "FUEL ADDED (LITERS)": fuelRecord.FueledLiters,
          CURRENCY: fuelRecord.Currency,
          "PRICE PER LITER": fuelRecord.PricePerLiter,
          TOTAL: fuelRecord.TotalFuelAmount,
          "DATE REPORTED": this.$store.getters.getTimestampzFormattedDate(
            fuelRecord.CreatedAt
          ),
        }));

      const csvContent = this.convertToCSV(newVehicleFuelingReportArray);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Vehicle_Fueling_Report_${new Date().toISOString().slice(0, 10)}.csv`
      );
      link.click();
    },
    convertToCSV(dataArray) {
      const headers = Object.keys(dataArray[0]);
      const rows = dataArray.map((obj) => headers.map((header) => obj[header]));
      const headerRows = headers.join(",");
      const csvRows = [
        headerRows,
        ...rows.map((row) =>
          row
            .map((value) => {
              if (typeof value === "string" && value.includes(",")) {
                return `"${value}"`; // Wrap text containing commas in double quotes
              }
              return value;
            })
            .join(",")
        ),
      ];
      return csvRows.join("\n");
    },
    heicToJpg(path) {
      fetch(path)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
          })
        )
        .then((conversionResult) => {
          var url = URL.createObjectURL(conversionResult);
          return url
        })
        .catch((e) => {
          console.log(e);
          return ''
        });
    },
    getFileExtension(url) {
      // Get the last part of the URL after the last dot (.)
      const lastDotIndex = url.lastIndexOf('.');
      if (lastDotIndex === -1) {
        return ''; // Handle cases where there's no extension
      }
      return url.slice(lastDotIndex + 1).toLowerCase(); // Extract and convert to lowercase
    }
  },
  mounted() {
    this.fetchVehicleFuelReports();
  },
};
</script>
