import VehiclesView from "./views/VehiclesView.vue";

const authRoutes = [
    {
        path: '/vehicles',
        component: VehiclesView,
        name: 'Vehicles',
        meta: { requiresAuth: true },

    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}