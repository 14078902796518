import VehicleIssuesView from "./views/VehicleIssuesView.vue";

const authRoutes = [
    {
        path: '/issues',
        component: VehicleIssuesView,
        name: 'Issues',
        meta: { requiresAuth: true },

    }
]

export default router => {
    authRoutes.forEach(route => {
        router.addRoute(route);
    });
}